@charset "UTF-8";
/* ================================================================= 
 License : e-TRUST Inc.
 File name : common.css
 Style : common
================================================================= */
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
@media only screen and (max-width: 1200px) {
  .bp1200none {
    display: none !important;
  }
}

@media only screen and (max-width: 834px) {
  .bp1200none {
    display: block !important;
  }
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex01 {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.8em;
  font-weight: 400;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow-x: hidden;
  letter-spacing: 0.05em;
  font-size: 15px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    margin-top: 30px;
  }
}

#contents {
  width: 100%;
  max-width: 1000px;
  padding: 0 0 50px 0;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding: 0 30px 50px 30px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #1f4589;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fff;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 834px) {
  .fixed {
    left: auto;
    background: inherit;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  position: relative;
}

#header {
  width: 100%;
}

#header .hed1 {
  height: 40px;
  background: url(../images/common/pt_1.png);
  z-index: 12;
  position: relative;
}

@media only screen and (max-width: 834px) {
  #header .hed1 {
    position: static;
    height: auto;
    background: none;
  }
}

#header .hed1 .inner {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  #header .hed1 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  #header .hed1 .inner {
    position: static;
  }
}

#header .hed1 .inner .flxL {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #header .hed1 .inner .flxL {
    display: none;
  }
}

#header .hed1 .inner .flxL .htxt {
  height: 40px;
  text-align: left;
  width: auto;
  display: flex;
  align-items: center;
}

#header .hed1 .inner .flxL:before {
  display: inline-block;
  content: url(../images/common/decoration_1.png);
  width: 144px;
  height: 74px;
  position: absolute;
  left: -150px;
  top: 0;
}

#header .hed1 .inner .flxL:after {
  display: inline-block;
  content: url(../images/common/decoration_2.png);
  width: 88px;
  height: 74px;
}

#header .hed1 .inner .flxR {
  max-width: 310px;
  position: absolute;
  right: 0;
  top: 0;
  width: 35%;
  padding: 20px;
  background: url(../images/common/pt_1.png) left top;
  border-radius: 10px;
  border-bottom: 5px solid #d7c1a2;
}

@media only screen and (max-width: 834px) {
  #header .hed1 .inner .flxR {
    display: none;
  }
}

#header .hed1 .inner .flxR a {
  color: #222;
  background: #fff;
  border-radius: 10px;
  display: block;
  padding: 15px;
}

#header .hed1 .inner .flxR a:before {
  content: "";
  background: url(../images/common/decoration_3.png) no-repeat center;
  width: 136px;
  height: 101px;
  position: absolute;
  left: -50px;
  top: 0;
}

#header .hed1 .inner .flxR a:after {
  content: "";
  background: url(../images/common/decoration_4.png) no-repeat center bottom;
  width: 71px;
  height: 60px;
  position: absolute;
  right: 0;
  bottom: 0;
}

#header .hed1 .inner .flxR a:hover {
  opacity: 1;
}

#header .hed1 .inner .flxR a dl dt {
  font-size: 25px;
  font-weight: 900;
}

#header .hed1 .inner .flxR a dl dt i {
  margin-right: 5px;
}

#header .hed1 .inner .flxR a dl dd {
  font-size: 14px;
}

#header .hed1 .inner .flxR a dl dd span {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  background: #6ba93c;
  padding: 5px;
  border-radius: 3px;
  line-height: 1;
  margin-right: 5px;
  margin-top: 5px;
}

#header .hed2 {
  z-index: 10;
  margin-top: 80px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 834px) {
  #header .hed2 {
    margin-top: 0;
  }
}

#header .hed2 .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #header .hed2 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  #header .hed2 .inner {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    position: fixed;
    top: 0;
    right: 0;
  }
}

.navwrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navwrap #nav_global {
  width: 100%;
}

.navwrap #nav_global > ul {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .navwrap #nav_global > ul {
    max-width: 100%;
  }
}

.navwrap #nav_global > ul > li {
  overflow: hidden;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
}

.navwrap #nav_global > ul > li > p > a {
  display: block;
  color: #333;
  font-weight: 500;
  padding: 5px 30px;
  font-size: 14px;
  line-height: 1;
  position: relative;
}

.navwrap #nav_global > ul > li > p > a:hover {
  color: #339b10;
  opacity: 1;
}

.navwrap #nav_global > ul > li > a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #222;
  font-weight: 500;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 1;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .navwrap #nav_global > ul > li > a {
    padding: 15px 15px;
  }
}

.global-menu-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    cursor: pointer;
    border-radius: 5px;
    height: 60px;
    width: 60px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 11;
    color: #339b10;
    line-height: 1;
    transition: 1s;
    color: #fff;
    background: #339b10;
  }
  .global-menu-btn:hover {
    color: #222;
    background: #fff;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    height: 100px;
    width: 100px;
    margin-left: auto;
    background: #f4f7f6;
    border-left: 1px solid #E6EAEA;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
}

@media only screen and (max-width: 834px) and (max-width: 834px) {
  .global-menu-close-btn {
    height: 60px;
    width: 60px;
  }
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    color: #000;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

.navdamy {
  display: none;
}

@media only screen and (max-width: 834px) {
  .navdamy {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    margin-bottom: 0;
    justify-content: center;
    height: 60px;
    width: 60px;
    z-index: 11;
    top: 10px;
    right: 10px;
    border-bottom: 0;
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    top: 0;
    right: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #eef2f2;
    width: 70%;
    height: 100%;
    opacity: 0;
    margin-left: auto;
    -webkit-transform: translateX(240%);
    -ms-transform: translateX(240%);
    transform: translateX(240%);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    justify-content: space-around;
    align-items: normal !important;
    width: 90%;
    margin: 5% auto;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
    background: none;
  }
  #hednav .wrap .navwrap #nav_global > ul {
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global > ul .pcnone {
    display: block !important;
  }
  #hednav .wrap .navwrap #nav_global > ul > li {
    padding: 0;
    text-align: left;
    position: relative;
    width: 100%;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a {
    line-height: 1.7em;
    color: #222 !important;
    font-weight: 700 !important;
    display: block;
    padding: 25px 36px !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a:hover {
    background: #ccc;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a img {
    width: 25%;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span {
    width: 70%;
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span b {
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li a span span {
    display: block;
    font-size: 0.8em;
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul {
    width: 100%;
    position: static;
    background: none;
  }
  #hednav .wrap .navwrap #nav_global > ul > li ul li a {
    background: #f7f7f7;
    color: #333;
    font-weight: 500;
    display: block;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p {
    position: relative;
    display: block;
    line-height: 1;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:hover:after {
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p span {
    display: block;
    margin-top: 7px;
    font-size: 0.7em;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p:after {
    border: solid 1px #ddd;
    font-weight: 100;
    content: "\f067";
    font-family: "Font Awesome 5 Pro";
    display: block;
    position: absolute;
    right: 0;
    top: -1px;
    bottom: 0;
    width: 77px;
    height: 77px;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f7f6;
  }
  #hednav .wrap .navwrap #nav_global > ul > li p.open:after {
    content: "\f00d";
  }
}

.MenuOpened {
  z-index: 13 !important;
  display: none;
}

@media only screen and (max-width: 834px) {
  .MenuOpened {
    display: block;
  }
  .MenuOpened .global-menu-btn {
    z-index: 0;
  }
  .MenuOpened .wrap {
    z-index: 10 !important;
    visibility: visible !important;
    opacity: 1 !important;
    background: rgba(0, 0, 0, 0.7) !important;
  }
  .MenuOpened .wrap .navwrap {
    opacity: 1 !important;
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh !important;
    margin-left: auto;
  }
  .MenuOpened .wrap .global-menu-close-btn {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

#mainArea {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 834px) {
  #mainArea {
    width: 150%;
    margin-left: -25%;
  }
}

#mainArea .maintxt {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mainArea .maintxt h1 {
  max-width: 970px;
  line-height: 1.7em;
  padding: 50px;
  background: rgba(255, 255, 255, 0.6);
  font-size: 30px;
  color: #2b1f0a;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
  #mainArea .maintxt h1 {
    font-size: 25px;
    max-width: 768px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #mainArea .maintxt h1 {
    font-size: 20px;
    max-width: 640px;
    padding: 30px;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .maintxt h1 {
    font-size: 16px;
    max-width: 460px;
    padding: 20px;
  }
}

#mainArea .maintxt h1 strong {
  width: 100%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  #mainArea .maintxt h1 strong {
    margin-bottom: 20px;
  }
}

#mainArea .maintxt h1 span {
  display: block;
  width: 31%;
  color: #fff;
  border-radius: 10px;
  padding: 30px;
  border: 10px solid #fff;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.5);
}

@media only screen and (max-width: 1000px) {
  #mainArea .maintxt h1 span {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #mainArea .maintxt h1 span {
    padding: 30px;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .maintxt h1 span {
    padding: 20px;
  }
}

#mainArea .maintxt h1 span:nth-child(2) {
  background: #d21e3b;
}

#mainArea .maintxt h1 span:nth-child(3) {
  background: #717379;
}

#mainArea .maintxt h1 span:nth-child(4) {
  background: #273969;
}

#mainArea img {
  width: 100% !important;
}

#mainArea button {
  display: none !important;
}

#mainArea2 {
  background-image: url(../images/common/main_1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 834px) {
  #mainArea2 {
    margin-bottom: 80px;
  }
}

#local-keyvisual {
  overflow: hidden;
  position: relative;
  background-position: center;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
}

#local-keyvisual #local-keyvisual-title {
  width: 100%;
}

#local-keyvisual #local-keyvisual-title h1 {
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1;
  color: #2b1f0a !important;
  z-index: 1;
  position: relative;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1.4em;
  padding: 80px 10px;
  text-align: center;
  font-size: 38px;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 834px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 28px;
  }
}

#local-keyvisual #local-keyvisual-title h1:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-title h1 span {
  font-size: 0.7em;
  display: block;
  letter-spacing: 0;
  line-height: 1;
  margin-top: 10px;
  border-top: 1px solid #2b1f0a;
  padding-top: 15px;
}

#local-keyvisual .local-keyvisual-bg {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pan1 {
  margin: 20px 0 50px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #221815;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  z-index: 1;
  position: relative;
}

footer#global_footer a {
  color: #333;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer {
  background: url(../images/common/cont_bg.jpg) no-repeat center;
  background-size: cover;
  padding: 80px 0;
}

@media only screen and (max-width: 1000px) {
  #footer {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 1200px) {
  #footer .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  #footer .inner {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  #footer .inner {
    padding: 0 30px;
  }
}

#footer .ttl {
  color: #222;
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1200px) {
  #footer .ttl {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #footer .ttl {
    font-size: 20px;
  }
}

#footer > p {
  line-height: 1.7em;
  margin-bottom: 20px;
}

#footer dl {
  margin: 30px auto 0;
  max-width: 580px;
  background: rgba(255, 255, 255, 0.6);
  padding: 30px 0;
}

#footer dl dt {
  background: #6ba93c;
  color: #fff;
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 30px;
  padding: 20px 0;
}

@media only screen and (max-width: 1200px) {
  #footer dl dt {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  #footer dl dt {
    font-size: 20px;
  }
}

#footer dl dd a {
  margin: 0 auto;
  padding: 20px;
  width: 450px;
  display: block;
  background: #fff;
  border: 1px solid #ccc;
}

#footer dl dd a p:nth-child(1) {
  font-size: 30px;
  font-weight: 900;
}

#footer dl dd a p:nth-child(1) i {
  margin-right: 5px;
}

#footer dl dd a p:nth-child(2) span {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  background: #6ba93c;
  padding: 5px;
  border-radius: 3px;
  line-height: 1;
  margin-right: 5px;
  margin-top: 5px;
}

small {
  background: url(../images/common/pt_1.png) left top;
  display: block;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    right: 10px;
    bottom: 140px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
  box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.1);
  border-radius: 50%;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #339b10;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #339b10;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #339b10;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.btn {
  overflow: hidden;
  position: relative;
  display: block;
  max-width: 140px;
  width: 100%;
  margin: 0;
  border-radius: 10px;
}

.btn a {
  border-radius: 10px;
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  font-size: 13px;
  display: block;
  padding: 15px 10px;
  line-height: 1;
  color: #fff;
  background: #333333;
}

.btn a:hover {
  opacity: 0.8 !important;
}

.btn2 {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: block;
  max-width: 300px;
  width: 100%;
  margin: 0;
  border-radius: 10px;
  margin: 0 auto;
}

.btn2 a {
  border-bottom: 5px solid #457421;
  border-radius: 10px;
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  font-size: 16px;
  display: block;
  padding: 20px 10px;
  line-height: 1;
  color: #fff;
  background: #6ba93c;
}

.btn2 a:hover {
  opacity: 1 !important;
  background: #457421;
}

.btn2 a i {
  margin-left: 10px;
}

.cont_1 {
  z-index: 1;
  position: relative;
}

.cont_1:before {
  content: "";
  background: url(../images/common/decoration_5.png) no-repeat right top;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: -25%;
}

.cont_1:after {
  content: "";
  background: url(../images/common/decoration_6.png) no-repeat left bottom;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: -25%;
}

.cont_1 .inner {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner {
    padding: 0 30px;
  }
}

.cont_1 .inner .midashi {
  max-width: 640px;
}

.cont_1 .inner .wrap {
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cont_1 .inner .wrap .box {
  width: 48%;
}

.cont_1 .inner .wrap .box a {
  display: block;
}

.cont_1 .inner .wrap .box img {
  width: 100% !important;
}

.cont_2 {
  z-index: 0;
  padding: 80px 0;
  background: url(../images/common/pt_1.png) left top;
  position: relative;
}

.cont_2:before {
  content: "";
  background: url(../images/common/decoration_7.png) no-repeat right top;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 5%;
}

.cont_2 .inner {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1200px) {
  .cont_2 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 .inner {
    padding: 0 30px;
  }
}

.cont_2 .inner .wrap .box {
  padding: 100px;
  width: 100%;
  margin-bottom: 30px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .cont_2 .inner .wrap .box {
    padding: 40px;
  }
}

.cont_2 .inner .wrap .box:nth-child(1) {
  background: url(../images/common/bg_1.jpg) no-repeat center;
  background-size: cover;
}

.cont_2 .inner .wrap .box:nth-child(2) {
  background: url(../images/common/bg_2.jpg) no-repeat center;
  background-size: cover;
}

.cont_2 .inner .wrap .box:nth-child(3) {
  background: url(../images/common/bg_3.jpg) no-repeat center;
  background-size: cover;
}

.cont_2 .inner .wrap .box h3 {
  color: #fff;
  font-weight: 900;
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .cont_2 .inner .wrap .box h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_2 .inner .wrap .box h3 {
    font-size: 20px;
  }
}

.cont_2 .inner .wrap .box div {
  color: #fff;
  text-align: center;
  font-size: 18px;
}

.cont_3 {
  position: relative;
}

.cont_3:after {
  content: "";
  background: url(../images/common/decoration_8.png) no-repeat left bottom;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: -25%;
}

.cont_3 .inner {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1200px) {
  .cont_3 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner {
    padding: 0 30px;
  }
}

.newswrap {
  height: 400px;
}

.newswrap .news {
  overflow-y: auto;
  z-index: 1;
  position: relative;
  height: 100%;
  padding-right: 40px;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    padding-right: 0;
  }
}

.newswrap .news .sns_list {
  border: 0;
}

.newswrap .news .sns_list > div {
  border-bottom: 1px dashed #333;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 834px) {
  .newswrap .news .sns_list > div {
    flex-direction: row !important;
  }
}

.newswrap .news .sns_list .sns_photo {
  padding: 0;
  margin-right: 3%;
}

.newswrap .news .sns_list .sns_text {
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.newswrap .news .sns_list .sns_text .detawrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.newswrap .news .sns_list .sns_text .detawrap .wrapdeta .sns_date {
  line-height: 1;
  font-size: 16px;
  font-weight: 800;
  color: #222;
}

.newswrap .news .sns_list .sns_text .detawrap .wrapdeta ul {
  display: none;
}

.newswrap .news .sns_list .sns_text h3 {
  margin-bottom: 0;
}

.newswrap .news .sns_list .sns_text h3 a span {
  display: block;
  font-weight: 300;
  font-size: 16px;
  color: #333;
}

.newswrap .news .sns_list .sns_text .detail {
  display: none;
}

.newswrap .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.newswrap .news dl dt {
  font-weight: 700;
}

.newswrap .news dl dt, .newswrap .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

/* title */
h2.mail.title {
  display: none !important;
}

#blog_detail {
  display: none;
}

.midashi h2 {
  font-weight: 700;
  line-height: 1.7em;
  color: #2b1f0a;
  font-size: 30px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1200px) {
  .midashi h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi h2 {
    font-size: 25px;
  }
}

.midashi2 h2 {
  font-weight: 700;
  line-height: 1.7em;
  color: #2b1f0a;
  font-size: 30px;
}

@media only screen and (max-width: 1200px) {
  .midashi2 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi2 h2 span {
  font-size: 1.5em;
  color: #ed1818;
}

.midashi3 h2 {
  padding-bottom: 15px;
  border-bottom: 3px double #4e362a;
  display: flex;
  align-items: center;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 18px;
  }
}

.midashi4 h2 {
  background: #f7f7f7;
  padding: 15px 20px 15px;
  position: relative;
  border: 1px solid #ccc;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.midashi4 h2:before {
  content: "";
  display: block;
  width: 4px;
  height: 15px;
  border-radius: 500px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffd200;
}

.midashi5 {
  text-align: center;
}

.midashi5 h2 {
  background: #f7f7f7;
  padding: 15px 20px 15px;
  position: relative;
  border: 1px solid #ccc;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi5 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi5 h2 {
    font-size: 20px;
  }
}

.midashi5 h2:before {
  content: "";
  display: block;
  width: 4px;
  height: 15px;
  border-radius: 500px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffd200;
}

.midashi5 h3 {
  display: inline-block;
  font-weight: 700;
  color: #222;
  font-size: 25px;
  border-bottom: 5px solid #15b419;
  padding-bottom: 15px;
}

@media only screen and (max-width: 1200px) {
  .midashi5 h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi5 h3 {
    font-size: 20px;
  }
}

.waku {
  padding: 20px;
  background: white;
  border-top: solid 5px #339b10;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
}

@media only screen and (max-width: 834px) {
  .key01 tr td:nth-child(1) {
    width: 35% !important;
  }
}

@media only screen and (max-width: 834px) {
  .comp2 dl {
    margin-top: -1px;
  }
}

.comp2 dt, .comp2 dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 1rem;
}

.comp2 dt {
  border-bottom: 1px solid #ccc !important;
}

.comp2 dd {
  border-bottom: 1px solid #eee !important;
}

@media only screen and (max-width: 834px) {
  .comp2 dl {
    border: 1px solid #ccc !important;
    display: flex;
    flex-flow: column;
  }
  .comp2 dt {
    border-bottom: 0 !important;
    background: #f7f7f7 !important;
    font-weight: bold;
    border-bottom: 1px solid #ccc !important;
    text-align: center !important;
  }
  .comp2 dt, .comp2 dd {
    padding: 5px !important;
    display: block !important;
    width: 100% !important;
    text-align: center !important;
  }
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

.slider2.slick-initialized {
  display: block;
}

.slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider2 .slick-dots {
  margin-top: 5px;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  justify-content: center;
  position: static !important;
}

.slider2 li {
  list-style: none;
  display: inline-block;
  width: 100px;
  hegiht: 100px;
  margin: 5px;
}

.slider2 li img {
  opacity: 0.5;
  width: 100px;
  height: auto;
  height: 100px !important;
  object-fit: cover;
}

.slider2 li.slick-active img {
  opacity: 1;
}

.slider2 .slick-prev {
  display: none;
}

.slider2 .slick-next {
  display: none;
}

.items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -1.6393% !important;
}

@media only screen and (max-width: 834px) {
  .items {
    margin-left: -4% !important;
  }
}

.items article {
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 23.3606% !important;
  margin-left: 1.6393% !important;
}

@media only screen and (max-width: 834px) {
  .items article {
    width: 46% !important;
    margin-left: 4% !important;
    margin-right: 0 !important;
    display: block;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .items article {
    width: 100% !important;
  }
}

.items article .photo {
  background-image: none !important;
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.items article .photo:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

@media only screen and (max-width: 640px) {
  .items article .photo:before {
    padding-top: 100%;
  }
}

.items article .photo img {
  display: block !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

.items article .summary {
  padding-top: 0 !important;
  text-align: left !important;
}

.items article .name {
  padding-top: 0 !important;
  width: 100%;
  display: block !important;
  text-align: left !important;
  margin: 10px 0;
  text-decoration: none !important;
}

.items article .price {
  width: 100%;
  display: block !important;
  text-align: left !important;
}

.items article button {
  margin-top: 15px !important;
  width: 100% !important;
  display: block !important;
  background: #f7f7f7 !important;
  padding: 10px !important;
  border: 1px solid #ccc !important;
  height: auto !important;
  text-indent: inherit !important;
}

.shopwrap {
  margin: 50px 0;
}

.shopwrap .items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -7.69231% !important;
}

@media only screen and (max-width: 834px) {
  .shopwrap .items {
    justify-content: space-between;
    margin-left: 0 !important;
  }
}

.shopwrap .items li {
  width: 17.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 50px;
}

@media only screen and (max-width: 834px) {
  .shopwrap .items li {
    width: 33% !important;
    margin-bottom: 20px;
    margin-left: 0 !important;
  }
}

.shopwrap .items li .itembox {
  display: block;
  color: #339b10;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2em;
}

.shopwrap .items li .itembox .summary {
  display: none;
}

.shopwrap .items li .itembox .price {
  text-align: left;
  font-weight: 700;
  color: #000;
  margin-top: 5px;
}

.shopwrap .items li .itembox .imgwrap {
  position: relative;
  width: 100% !important;
  margin-bottom: 15px;
}

.shopwrap .items li .itembox .imgwrap img {
  width: 100% !important;
  height: 100% !important;
}

div.search {
  margin: 50px 0;
}

div.search #keyword .select {
  position: relative;
}

div.search #keyword .select:after {
  top: 15px !important;
  pointer-events: none;
}

.item {
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 834px) {
  .item {
    max-width: 100%;
  }
}

.item .photo {
  width: 100% !important;
  max-width: 50% !important;
}

@media only screen and (max-width: 834px) {
  .item .photo {
    max-width: 100% !important;
  }
}

.item .item_view {
  margin-left: 0 !important;
  min-width: auto　 !important;
  max-width: 45% !important;
}

@media only screen and (max-width: 834px) {
  .item .item_view {
    max-width: 100% !important;
  }
}

.photo {
  flex-direction: column !important;
}

.photo .large {
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

.photo .large img {
  width: 100%;
}

.photo .small {
  min-width: 100% !important;
  max-width: 100% !important;
}

.photo .small ul {
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.36842%;
  margin-bottom: 50px;
}

.photo .small ul li {
  position: relative;
  text-align: center;
  border-bottom: 0;
  width: 17.63158%;
  height: 17.63158%;
  margin-left: 2.36842% !important;
  margin-bottom: 10px !important;
  flex-basis: auto !important;
}

.photo .small ul li:before {
  position: relative;
  z-index: 1;
  content: "";
  padding-top: 100%;
  display: block;
  width: 100% !important;
}

.photo .small ul li img {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.photo .small .owl-dots {
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.photo .small .owl-dots .owl-dot {
  border: 6px solid #222;
  border-radius: 50%;
  margin: 0 5px;
}

.photo .small .owl-dots .owl-dot.active {
  border-color: #5d5d5d;
}

.item_view h2 {
  color: #222;
  font-weight: 500;
}

.item_view h2 span {
  display: none !important;
}

.item_view .item_option {
  margin-top: 20px;
}

.item_view .item_option dl {
  width: 100% !important;
}

.item_view .item_option .addcart .count {
  width: 100% !important;
}

.item_view .item_option .addcart .addcart {
  transition: 1s;
  height: 100%;
  padding: 15px;
  width: 100% !important;
  background: #5d5d5d;
  color: #fff;
  text-indent: 0;
}

.item_view .item_option .addcart .addcart:hover {
  opacity: 1;
  background: #3e3e3e;
}

.item_view .item_option .addcart a {
  color: #9a3000;
  text-decoration: none;
}

.item_view .item_option .addcart a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f06a";
  margin-right: 5px;
}

.item_view .struct {
  font-size: 13px;
  margin-top: 10px !important;
}

.item_view .struct .sku {
  margin: 10px 0 !important;
}

.item_view .struct .sku .price {
  font-size: 16px !important;
  line-height: 1 !important;
}

.item_view .sku2 {
  margin-top: 20px;
}

.item_view p.sku1 {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  background: #f7f7f7;
  padding: 15px 15px 10px 15px;
}

.item_view p.sku1:before {
  text-align: center;
  background: #ccc;
  padding: 8px 0;
  width: 100%;
  content: "カラーを選択してください";
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}

.item_view .sku1 ul {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: #f7f7f7;
  padding: 0 15px 15px 15px;
  margin-top: 0 !important;
  margin-left: 0 !important;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item_view .sku1 ul li.selected {
  background: #000 !important;
}

.item_view .sku1 ul li {
  background: #fff;
  width: 48%;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

.item_view .attr {
  margin-top: 20px;
}

.item_view .attr h3 {
  display: none;
}

.item_view .attr dl {
  display: flex;
  border: 0;
  border-top: 1px solid #222;
  border-bottom: none;
  font-size: 13px;
}

.item_view .attr dl dt {
  padding: 10px;
  min-width: 25%;
  background: none;
  border-right: 0;
}

.item_view .attr dl dd {
  padding: 10px;
  width: 100%;
}

.item_view .attr dl:last-child {
  border-bottom: 1px solid #222;
}

.item_view .detail {
  margin-top: 0px;
  font-size: 13px;
  color: #222;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media only screen and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

div.items {
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
  margin-bottom: 50px;
  margin-top: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  div.items {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 640px) {
  div.items {
    justify-content: space-between;
    margin-left: 0;
  }
}

div.items article {
  text-align: center;
  border-bottom: 0;
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-right: 0 !important;
  margin-bottom: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  div.items article {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  div.items article {
    width: 100%;
    margin-left: 0;
    margin-bottom: 50px;
  }
}

div.items article a {
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
}

div.items article .imgwrap {
  position: relative;
}

div.items article .imgwrap:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

div.items article .imgwrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title,
.wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2,
.item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a,
.item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover,
.item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.shopwrap .items .imgwrap {
  position: relative;
}

.shopwrap .items .imgwrap:after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 145%;
  visibility: visible !important;
}

.shopwrap .items .imgwrap > img, .shopwrap .items .imgwrap video {
  position: absolute;
  left: 0;
  top: 1;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
